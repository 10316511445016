<template>
  <div>
    <div class="col-md-12">
      <div class="row">
        <div class="col-xl-4 col-lg-4 col-md-4 col-sm-12">
          <h4>Free website</h4>
          <p>Start with a free website plan with a free domain name under Yelko. This plan is free forever. Try searching for a free website name available.</p>
          <p>
            Eg:
            <code>yourname.yelko.com</code>
          </p>
        </div>
        <div class="col-xl-8 col-lg-8 col-md-8 col-sm-12">
          <div class="kt-portlet">
            <div class="kt-portlet__head">
              <div class="kt-portlet__head-label">
                <h3 class="kt-portlet__head-title">Get a free website</h3>
              </div>
            </div>
            <div class="kt-portlet__body">
              <template>
                <sub-domain :domain_name="domain_name"></sub-domain>
              </template>
            </div>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-xl-4 col-lg-4 col-md-4 col-sm-12">
          <h4>Existing Domain</h4>
          <p>You can connect your existing domain name to our website or ecommerce plan. You will need admin privilege of your domain to do so.</p>
        </div>
        <div class="col-xl-8 col-lg-8 col-md-8 col-sm-12">
          <div class="kt-portlet">
            <div class="kt-portlet__head">
              <div class="kt-portlet__head-label">
                <h3 class="kt-portlet__head-title">Connect your domain</h3>
              </div>
            </div>
            <div class="kt-portlet__body">
              <custom-domain :domain_name="domain_name"></custom-domain>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-xl-4 col-lg-4 col-md-4 col-sm-12">
          <h4>New Domain</h4>
          <p>If you don't have a domain name yet, you can look up for a domain name and purchase one today.</p>
        </div>
        <div class="col-xl-8 col-lg-8 col-md-8 col-sm-12">
          <register-domain></register-domain>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Website from "@/services/Websites/WebsiteService";
import SubDomain from "./SubDomain";
import CustomDomain from "./CustomDomain";
import { SET_BREADCRUMB } from "@/store/breadcrumbs.module";
import { mapState } from "vuex";
import ApiService from "@/common/api.service";
import { validationMixin } from "vuelidate";
import { email, required, minLength, sameAs } from "vuelidate/lib/validators";
import DomainVerificationService from "@/services/domain/DomainVerificationService";
import RegisterDomain from "@/views/domain/RegisterDomain";

const WebsiteService = new Website();
const verificationService = new DomainVerificationService();

export default {
  name: "website-getting-started",
  mixins: [validationMixin],
  data() {
    return {
      domain_name: null
    };
  },
  components: {
    RegisterDomain,
    SubDomain,
    CustomDomain
  },

  computed: {
    ...mapState({
      errors: state => state.auth.errors
    })
  },
  methods: {
    verifyNow(domain) {
      this.$router.push({
        name: "domain-verfication",
        params: { domain_name: domain }
      });
    }
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "New Website", route: "dashboard" }
    ]);
  }
};
</script>
<style scoped>
.card-header a {
  font-size: 1.25em;
  text-align: left;
}
</style>
